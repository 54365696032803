<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside style="border-redius: 50%">
        <b-link>
          <b-img ref="previewEl" rounded :src="profile_image && profile_image !== null
              ? profile_image
              : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
            " height="80" width="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mt-1 ml-1"
          @click="profileimageclick()">
          Upload
        </b-button>
        <div style="display: none">
          <b-form-file v-model="profileFile" id="fileUpload" @input="handleFileChange($event, 'profilephoto')" />
        </div>
        <!--/ upload button -->

        <!-- reset -->
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm" class="mt-1 ml-1"
          @click="profile_image = null">
          reset
        </b-button>
        <!--/ reset -->
      </b-media-body>
    </b-media>
    <div class="mt-2">
      <h4 class="font-weight-bold">
        Username : <span class="text-danger">{{ code }} </span>
      </h4>
      <h6 class="font-weight-bold" @dblclick="handleCopy">
        Referral link : <span class="text-primary">{{ referral_link }} </span>
      </h6>
    </div>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2" @submit.prevent="submitForm">
        <b-row>
          <b-col sm="6">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Name">
                <b-form-input v-model="name" placeholder="Enter  Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label>Email</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Name">
                <b-form-input v-model="email" type="email" placeholder="Enter Email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label>Mobile</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Mobile">
                <b-form-input v-model="mobile" type="number" placeholder="Enter Mobile" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label>Gender</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Gender">
                <v-select v-model="gender" placeholder="None" :options="['Male', 'Female']" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label>Birth Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Birth Date">
                <flat-pickr v-model="birthdate" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),
                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label>Anniversary Date</label>

              <flat-pickr v-model="anniversarydate" class="form-control" placeholder="Enter Annivarsary Date" :config="{
                dateFormat: 'd/m/Y',
                maxDate: new Date(),
              }" style="background-color: transparent" />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Current Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Address Line 1</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 1">
                <b-form-input v-model="curaddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Address Line 2</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 2">
                <b-form-input v-model="curaddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 2" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required|digits:6" name="Pin Code">
                <b-form-input v-model="curpincode" maxlength="6" @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="City">
                <v-select v-model="curcity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="curcityOption"
                  @input="clickArea($event)" placeholder="None" label="City" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Area">
                <v-select v-model="curarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="PostOfficeName"
                  :options="curareaOption" placeholder="None" @input="handleCurPincode()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Taluka</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Taluka">
                <b-form-input v-model="curtaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Taluka" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <v-select v-model="curstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                  placeholder="None" label="State" @input="handleCurCity()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Permanent Address</span>
            <!-- <b-card-code title="Current Address" no-body>
              <b-col></b-col>
            </b-card-code> -->
            <b-form-checkbox @input="handlePermanent" class="ml-2" inline>
              Same As Current Address
            </b-form-checkbox>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Address Line 1</label>
              <b-form-input v-model="peraddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 1" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Address Line 2</label>
              <b-form-input v-model="peraddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 2" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Pin Code</label>
              <b-form-input v-model="perpincode" maxlength="6" @input="clickPerPincode($event)"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>City</label>
              <v-select v-model="percity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="percityOption"
                label="City" @input="clickPerArea($event)" placeholder="None" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Area</label>
              <v-select v-model="perarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perareaOption"
                placeholder="None" @input="handlePincode()" label="PostOfficeName" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input v-model="pertaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Taluka" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>State</label>
              <v-select v-model="perstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                placeholder="None" label="State" @input="handleCity()" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1" type="submit"
              @click.prevent="submitForm">Save changes</b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" type="reset" class="mt-2"
              @click="handleBack()">Back</b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Datepicker from "vuejs-datepicker";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from "moment";
import axios from "@/components/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormCheckbox,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    vSelect,
    flatPickr,
    Datepicker,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      // optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      name: "",
      email: "",
      gender: "",
      mobile: "",
      birthdate: moment(new Date()).format("DD/MM/yyyy"),
      anniversarydate: null,
      profile_image: "",
      curaddressline1: "",
      referral_link: "",
      curaddressline2: "",
      curarea: "",
      curtaluka: "",
      curcity: "",
      curpincode: "",
      curstate: "",
      test: "",
      peraddressline1: "",
      peraddressline2: "",
      perarea: "",
      pertaluka: "",
      percity: "",
      perpincode: "",
      perstate: "",
      curcityOption: [],
      curareaOption: [],
      percityOption: [],
      perareaOption: [],
      stateOptions: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fromState: "",
      fromCurState: "",
      perStateData: "",
    };
  },
  mounted() {
    this.getUserData();
    this.getState();
  },

  methods: {
    handlePermanent(e) {
      if (e == true) {
        this.perarea = this.curarea;
        this.perstate = this.curstate;
        this.percity = this.curcity;
        this.pertaluka = this.curtaluka;
        this.perpincode = this.curpincode;
        this.peraddressline1 = this.curaddressline1;
        this.peraddressline2 = this.curaddressline2;
      } else {
        this.perarea = "";
        this.perstate = "";
        this.percity = "";
        this.pertaluka = "";
        this.perpincode = "";
        this.peraddressline1 = "";
        this.peraddressline2 = "";
      }
    },
    init() {
      this.handleCity();
      this.handleCurCity();
      this.handleCurArea();
      this.handleArea();
    },
    handlePincode() {
      if (this.fromState == true) {
        this.perpincode = this.perarea.Pincode;
      }
    },
    handleCurPincode() {
      if (this.fromCurState == true) {
        this.curpincode = this.curarea.Pincode;
      }
    },
    handleCopy() {
      console.log("opopop");

      navigator.clipboard.writeText(this.referral_link).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            variant: "success",
            text: "Referral link copied",
          },
        });
      });
    },
    async handleCurCity() {
      this.fromCurState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.curstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curcityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCity() {
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.perstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.percityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    profileimageclick() {
      document.getElementById("fileUpload").click();
    },
    async handleFileChange(e, type) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const imagePath = process.env.VUE_APP_IMAGE_PATH;
      console.log(imagePath, "imagePath");
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          // console.log(process.env.IMAGE_PATH,"jay-=-")
          this.profile_image = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleBack() {
      this.$router.push("/");
    },
    async getUserData() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.userData.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }).then((response) => {
        this.getEditValue(response.data.data[0]);
      });
    },
    getEditValue(item) {
      this.name = item.name;
      this.test = item.email;
      this.email = item.email;
      this.gender = item.gender;
      this.mobile = item.mobile;
      this.birthdate =
        item.birthdate == "0000-00-00"
          ? new Date()
          : item.birthdate.split("-").reverse().join("/");
      this.profile_image = item.profile_image;
      this.curaddressline1 = item.curaddressline1;
      this.curaddressline2 = item.curaddressline2;
      this.curarea = item.curarea;
      this.curtaluka = item.curtaluka;
      this.curcity = item.curcity;
      this.curpincode = item.curpincode;
      this.curstate = item.curstate;
      this.peraddressline1 = item.peraddressline1;
      this.peraddressline2 = item.peraddressline2;
      this.perarea = item.perarea;
      this.pertaluka = item.pertaluka;
      this.percity = item.percity;
      this.perpincode = item.perpincode;
      this.perstate = item.perstate;
      this.code = item.username;
      this.referral_link = item.referral_link;
      this.anniversarydate = moment(item.anniversarydate).format("DD/MM/yyyy");
      // this.clickPincode()
      // this.clickPerPincode()
      this.init();
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.percity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.perareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickPerArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
      // this.perareaOption = []
      // this.perStateData.map((item) => {
      //   if (item.City == e) {
      //     this.perareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPerPincode() {
      this.percity = "";
      this.perarea = "";
      this.perstate = "";
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.perpincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.perpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.percityOption = [];

            this.perareaOption = response.data.data;
            this.perStateData = response.data.data;
            this.perstate = response.data.data[0].State;
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.percityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async handleCurArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.curcity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromCurState == true) {
        this.handleCurArea();
      }
      // this.curareaOption = []
      // this.curStateData.map((item) => {
      //   if (item.City == e) {
      //     this.curareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPincode() {
      this.curcity = "";
      this.curarea = "";
      this.curstate = "";
      this.curareaOption = [];
      this.curcityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.curpincode)) {
        this.fromCurState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.curpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.curcityOption = [];
            this.curStateData = response.data.data;
            this.curareaOption = response.data.data;
            this.curstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.curcityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    submitForm(e) {
      const data = {
        name: this.name,
        email: this.email,
        gender: this.gender,
        mobile: this.mobile,
        birthdate: this.birthdate.split("/").reverse().join("-"),
        profile_image: this.profile_image,
        curaddressline1: this.curaddressline1,
        curaddressline2: this.curaddressline2,
        curarea: this.curarea.PostOfficeName,
        curtaluka: this.curtaluka,
        curcity: this.curcity.City,
        curpincode: this.curpincode,
        curstate: this.curstate.State,
        peraddressline1: this.peraddressline1,
        peraddressline2: this.peraddressline2,
        perarea: this.perarea ? this.perarea.PostOfficeName : "",
        pertaluka: this.pertaluka,
        percity: this.percity ? this.percity.City : "",
        perpincode: this.perpincode,
        perstate: this.perstate ? this.perstate.State : "",
        anniversarydate: moment(this.anniversarydate).format("yyyy-MM-DD"),
      };

      if (this.test == data.email) {
        delete data.email;
      }
      // var ifEdit = this.$route.query.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: "PUT",
            url: `${baseApi}/auth/userupdate/${this.userData.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.$store.dispatch("app/getUserData");
              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/");
            })
            .catch((error) => {
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
